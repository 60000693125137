import React from "react"
import Header from "./header"
import Footer from "./footer"
import GlobalStyle from "./GlobalStyle"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = props => {
  return (
    <Container>
      <GlobalStyle />
      <Header />
      {props.children}
      <Footer />
    </Container>
  )
}

export default Layout
