import React from "react"
import Layout from "../../components/layout/layout"
import CategoryList from "../../components/index/CategoryList"
import styled from "styled-components"

const Container = styled.div`
  margin: 5em 0;
`

const Category = ({ data }) => {
  return (
    <div>
      <Layout>
        <Container>
          <CategoryList />
        </Container>
      </Layout>
    </div>
  )
}

export default Category
