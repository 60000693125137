import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Gatsby from "../../assets/Gatsby_Monogram.svg"

const Container = styled.section`
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    max-width: 1000px;
  }
`

const Category = styled.div`
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  margin: 1em;

  @media (min-width: 768px) {
    height: 350px;
    width: 32%;
    margin: 1em;
  }
`

const Title = styled(Link)`
  font-weight: 700;
  margin: 2em 0;
  text-decoration: none;
  color: black;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`
const Paragraph = styled.p`
  line-height: 2em;
`

const Logo = styled(Link)`
  margin-top: 2em;
  width: 50px;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`

function CategoryList() {
  return (
    <Container>
      <Category>
        <Logo to={"/blog/category/gatsby"}>
          <Gatsby />
        </Logo>
        <Title to={"/blog/category/gatsby"}>Gatsby</Title>
        <Paragraph>
          Reactベースのフレームワーク。SSG(静的サイトジェネレーター：Static Site
          Generator)と呼ばれ短時間で高速なサイトを構築することが可能です。
        </Paragraph>
      </Category>
    </Container>
  )
}

export default CategoryList
